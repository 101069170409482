import React from "react"
import { graphql } from "gatsby"

import AwardeeDetail from "./awardee"


const DriehausDetail = ({ data }) => (
  <AwardeeDetail name={data.driehaus.project}
                 award={`${data.driehaus.year} Driehaus Award, ${data.driehaus.place} Place`}
                 architect={`${data.driehaus.architect} for ${data.driehaus.sponsor_owner}`}
                 address={data.driehaus.address}
                 image={data.driehaus.fields.imgPath}
                 description={data.driehaus.description}
                 video={data.driehaus.video_link} />
)


export const query = graphql`
  query($slug: String!) {
    driehaus : driehausGeocodedCsv(fields: { slug: { eq: $slug } } ) {
      place
      year
      project
      architect
      sponsor_owner
      address
      description
      video_link
      fields {
        imgPath {
          childImageSharp {
            fluid(maxWidth: 1140) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default DriehausDetail
